let key = {};
let IPFS_IMG = " https://naifty.infura-ipfs.io/ipfs/";
var networkVersion = "";
let Back_Url = "";
var FRONT_URL = "";
var ImG = "";
var EnvName = "demo";
var Front_market_Url = "";
var ADMIN_URL = "";
var ENCODEKEY = "";
var networkTransUrl = "";
var RPC_URL = "";
if (EnvName === "local") {
  Back_Url = "http://localhost:3331/v1/admin";
  Front_market_Url = "http://localhost:3001/oxeomaadmin";
  ImG = "http://localhost:3331";
  networkTransUrl = "https://testnet.bscscan.com/tx";
  var tradeAddress = "0x11F572cB68B739e57C48F40ea7403cae9883EFcb".toLowerCase();
  var networkVersion = "0x61";
  var chainId = "0x61";
  var BNBCHAIN = 97;
  var BNBversion = "0x61"; //
  var TradeContract =
    "0x11F572cB68B739e57C48F40ea7403cae9883EFcb".toLowerCase();
  var BNBprovider =
    "https://endpoints.omniatech.io/v1/bsc/testnet/public".toString();
  var Back_Url_Token = "http://localhost:3331/v1";
  var Block_URL = "https://testnet.bscscan.com";
  var RewardtokenAddress =
    "0x7CAB80ce0E55F46378E493B584eE61aD68878f11".toLowerCase();
  var Rewardtoken = "CAKE";
  var DefaultChainId = 97;
  var chain_list = [97, 11155111];
  var chainCurrency = {
    name: "BNB",
    symbol: "BNB",
    decimals: 18,
  };
  var chainName = "BSC-Testnet";
  var BlockChain = "Binance Testnet";
  var COIN_NAME = "BNB";
  var DefaultKey = "BSC";
}

if (EnvName === "demo") {
  Back_Url = "https://backend-oxeoma-clone.maticz.in/v1/admin";
  Front_market_Url = "https://oxeoma-clone.maticz.in/";
  ADMIN_URL = "https://oxeoma-clone-admin.maticz.in/";
  ImG = "https://backend-oxeoma-clone.maticz.in";
  networkTransUrl = "https://testnet.bscscan.com/tx";
  var tradeAddress = "0x11F572cB68B739e57C48F40ea7403cae9883EFcb".toLowerCase();
  var networkVersion = "0x61";
  var chainId = "0x61";
  var BNBCHAIN = 97;
  var BNBversion = "0x61"; //
  var TradeContract =
    "0x11F572cB68B739e57C48F40ea7403cae9883EFcb".toLowerCase();
  var BNBprovider =
    "https://endpoints.omniatech.io/v1/bsc/testnet/public".toString();
  var Back_Url_Token = "http://localhost:3331/v1";
  var Block_URL = "https://testnet.bscscan.com";
  var RewardtokenAddress =
    "0x7CAB80ce0E55F46378E493B584eE61aD68878f11".toLowerCase();
  var Rewardtoken = "CAKE";
  var DefaultChainId = 97;
  var chain_list = [97, 11155111];
  var chainCurrency = {
    name: "BNB",
    symbol: "BNB",
    decimals: 18,
  };
  var chainName = "BSC-Testnet";
  var BlockChain = "Binance Testnet";
  var COIN_NAME = "BNB";
  var DefaultKey = "BSC";
}

if (EnvName === "client") {
  Back_Url = "https://api.nfteco.eu/v1/admin";
  Front_market_Url = "https://nfteco.eu";
  ADMIN_URL = "https://controls.nfteco.eu";
  ImG = "https://api.nfteco.eu";
  networkTransUrl = "https://testnet.bscscan.com/tx";
  var tradeAddress = "0x11F572cB68B739e57C48F40ea7403cae9883EFcb".toLowerCase();
  var networkVersion = "0x61";
  var chainId = "0x61";
  var BNBCHAIN = 97;
  var BNBversion = "0x61"; //
  var TradeContract =
    "0x11F572cB68B739e57C48F40ea7403cae9883EFcb".toLowerCase();
  var BNBprovider =
    "https://endpoints.omniatech.io/v1/bsc/testnet/public".toString();
  var Back_Url_Token = "https://api.nfteco.eu/v1";
  var Block_URL = "https://testnet.bscscan.com";
  var RewardtokenAddress =
    "0x7CAB80ce0E55F46378E493B584eE61aD68878f11".toLowerCase();
  var Rewardtoken = "CAKE";
  var DefaultChainId = 97;
  var chain_list = [97, 11155111];
  var chainCurrency = {
    name: "BNB",
    symbol: "BNB",
    decimals: 18,
  };
  var chainName = "BSC-Testnet";
  var BlockChain = "Binance Testnet";
  var COIN_NAME = "BNB";
  var DefaultKey = "BSC";
}

key = {
  AdminAPI: `${Back_Url}`,
  Back_Url: `${Back_Url_Token}`,
  chainId: chainId,
  BNBProvider: BNBprovider,
  BNBCHAIN: BNBCHAIN,
  bnbver: BNBversion,
  ENVname: EnvName,
  networkTransUrl: networkTransUrl,
  tradeAddress: tradeAddress,
  ImG: ImG,
  IPFS_IMG: IPFS_IMG,
  TradeContract: TradeContract,
  noimg: require("../assets/images/No_image.webp"),
  FRONT_URL: FRONT_URL,
  Front_market_Url: Front_market_Url,
  networkVersion: networkVersion,
  ENCODEKEY: "oxeoma30-46",
  EMAIL: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
  MOBILE: /^\d{10}$/,
  OTP: /^\d{4}$/,
  NumOnly: /^[1-9]\d*(\.\d+)?$/,
  PASSWORD: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/,
  OnlyAlbhabets: /^(?:[A-Za-z]+)(?:[A-Za-z0-9 _]*)$/,
  notSpecil: /^[a-zA-Z0-9]+$/,
  OnlyAlphSpecial: /^[A-Za-z_@.#&+-]*$/,
  Block_URL: Block_URL,
  RewardtokenAddress: RewardtokenAddress,
  Rewardtoken: Rewardtoken,
  DefaultChainId: DefaultChainId,
  chain_list: chain_list,
  chainName: chainName,
  BlockChain: BlockChain,
  COIN_NAME: COIN_NAME,
  RPC_URL: RPC_URL,
  chainCurrency: chainCurrency,
  DefaultKey: DefaultKey,
};

export default key;
